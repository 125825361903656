import { render, staticRenderFns } from "./ButtonRelief.vue?vue&type=template&id=5eed3df3&"
import script from "./ButtonRelief.vue?vue&type=script&lang=js&"
export * from "./ButtonRelief.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports